<template>
  <v-container>
      <v-card
          class="mx-auto text-center pa-5"
          max-width="600">
        <v-card-title>
          Créer un nouvel utilisateur
        </v-card-title>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-text-field
              v-model="user.firstname"
              :counter="10"
              label="Prénom"
              required
          ></v-text-field>

          <v-text-field
              v-model="user.lastname"
              :counter="10"
              label="Nom"
              required
          ></v-text-field>

          <v-text-field
              v-model="user.email"
              label="Email"
              required
          ></v-text-field>

          <v-text-field
              id="password"
              label="Mot de passe"
              name="password"
              prepend-icon="lock"
              type="password"
              color="#14ad95"
              v-model.trim="user.password"
          />

          <v-text-field
              v-model="user.ville"
              label="Ville"
          ></v-text-field>

          <v-text-field
              v-model="user.fonction"
              label="Fonction"
          ></v-text-field>

          <v-switch
              v-model="user.role"
              inset
              :label="`Admin: ${user.role.toString()}`"
          ></v-switch>

<!--          <v-file-input-->
<!--              v-model="doc"-->
<!--              label="Document"-->
<!--              @change="uploadFile"-->
<!--          ></v-file-input>-->

          <v-checkbox
              v-model="checkbox"
              :rules="[v => !!v || 'You must agree to continue!']"
              label="Do you agree?"
              required
          ></v-checkbox>

          <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              @click="create"
          >
            Ajouter
          </v-btn>

          <v-btn
              color="error"
              class="mr-4"
              @click="reset"
          >
            Annuler
          </v-btn>

        </v-form>
      </v-card>
      <v-card>
        <v-simple-table>
          <template v-slot:default>
            <thead> Listes des Utilisateurs
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Description
              </th>
              <th class="text-left">
                Category
              </th>
              <th class="text-left">
                Document
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="user in users" :key="user.uid"
            >
              <td>{{ user.firstname }}</td>
              <td>{{ user.description }}</td>
              <td>{{ user.category }}</td>
              <td><v-img :src="user.image" max-height="50" max-width="50"/></td>
              <td><a :src="user.fileItem">Download</a></td>
              <td>
                <v-btn color="teal" outlined small rounded ><v-icon>mdi-loupe</v-icon></v-btn> |
                <v-btn color="yellow" outlined small rounded ><v-icon>mdi-swap-horizontal</v-icon></v-btn> |
                <v-btn color="red" outlined small rounded @click="deleteFile(user.name)" ><v-icon>mdi-delete</v-icon></v-btn>
                <v-dialog
                    v-model="dialog"
                    width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        elevation="2"
                        fab
                        v-bind="attrs"
                        v-on="on"
                        @click="editFile(user)"
                    >
                      <v-icon>
                        fa-plus
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-form>
                      <v-text-field
                          v-model="user.firstname"
                          :counter="10"
                          label="Name"
                          required
                      ></v-text-field>

                      <v-text-field
                          v-model="file.description"
                          label="Description"
                      ></v-text-field>

                      <v-select
                          v-model="file.category"
                          :items="items"
                          :rules="[v => !!v || 'Item is required']"
                          label="Item"
                          required
                      ></v-select>

                      <v-file-input
                          v-model="file.fileItem"
                          label="Document"
                          @click="uploadFile"
                      ></v-file-input>

                      <v-checkbox
                          v-model="checkbox"
                          :rules="[v => !!v || 'You must agree to continue!']"
                          label="Do you agree?"
                          required
                      ></v-checkbox>

                      <v-btn
                          :disabled="!valid"
                          color="success"
                          class="mr-4"
                      >
                        Ajouter
                      </v-btn>

                      <v-btn
                          color="error"
                          class="mr-4"
                          @click="reset"
                      >
                        Annuler
                      </v-btn>
                    </v-form>
                  </v-card>
                </v-dialog>

              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
  </v-container>
</template>

<script>
// import { collection, addDoc, getDocs, query } from "firebase/firestore";
import {db, firebase} from "../main";
import {createUserWithEmailAndPassword, getAuth, updateProfile} from "firebase/auth";
export default {
  name: "Files",
  data: () => ({
    valid: true,
    dialog: false,
    items: [
      'Communication',
      'Ressources Humaines',
      'Process & Formation',
    ],
    roles: [
      'SuperAdmin',
      'Admin',
      'User'
    ],
    doc:'',
    users:[],
    user:{
      id: null,
      firstname: "",
      lastname: "",
      email:"",
      password:"",
      ville: "",
      fonction: null,
      image:"https://findicons.com/files/icons/312/folder/512/documents.png",
      fileItem: null,
      role: false,
    },
    uploadTask: null,
    uploadState: null,
    activeItem:null,
    checkbox: false,
  }),
  firestore(){
      return{
        files: db.collection("files"),
      }
  },
  methods: {
    async getUsers(){
      await db.collection("users").get().then((querySnapshot) => {
        const users = []
          querySnapshot.forEach((doc) => {

            users.push(doc.data());
          })
        this.users = users
        });
      return this.users
    },
    create: function () {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, this.user.email, this.user.password)
          .then((userCredential) => {
            const user = userCredential.user;
            db.collection("users").doc(user.uid).set({
              lastname: this.user.lastname,
              firstname: this.user.firstname,
              email: this.user.email,
              password: this.user.password,
              ville: this.user.ville,
              fonction: this.user.fonction,
              admin: this.user.role

            });
            // Signed in
            console.log(user);
            updateProfile( user, {
              displayName: this.user.firstname,
              photoURL: "https://pixabay.com/get/g34a6e36c4750d6244d0b9eb21632450d808c2e469bd65bc53b2638311713e0f0c1c78e33ef35eb7a441cb7c89764faa46258b937fb1d8d0118b4869cd670c1da_1280.png"
            }).then ( () => {
              //Enregistrement de l'utilisateur en bdd
              this.saveUserToUsersRef(user).then(() => {
                this.$store.dispatch("setUser", user)
                this.$router.push("/dashboard");
              })
            },error => {
              console.log(error)
              this.errors.push(error.message)
              this.isLoading = false
            })
          })
          .catch((error) => {
            const errorCode = error.code;
            console.log(errorCode);
            const errorMessage = error.message;
            console.log(errorMessage);
          });
    },
    saveUserToUsersRef(user){
      return this.usersRef.child(user.uid).set({
        name: user.displayName,
        avatar: user.photoURL
      })
    },
    updateFile(){
      db.collection("files")
          .where('name', '==', this.file.name).get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              doc.ref.set({
                name: this.file.name,
                description: this.file.description,
                category: this.file.category,
                image: "https://findicons.com/files/icons/312/folder/512/documents.png",
                fileItem: this.uploadFile()
              }).then(this.getProducts)
            })
          })
    },
    deleteFile(name){
      if(confirm("Are you sure ?")){
        db.collection("files")
            .where('name', '==', name).get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                doc.ref.delete().then(this.getFiles)
              })
            })
      }
    },
    uploadFile(){
      let doc = this.doc
      let storageRef = firebase.storage().ref("documentations/"+doc.name)
      this.uploadTask = storageRef.put(doc);
      this.uploadTask.on("state_changed", () =>{console.log("next step")
        this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
          console.log("File available at", downloadURL);
          this.file.fileItem = downloadURL
        })
      })
      return this.file.fileItem
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.remove()
    },
  },
  created(){
    this.getUsers();
  }
}
</script>

<style scoped>

</style>